.container {
  width: 60vw;
  text-align: left;
  margin-bottom: 15vh;
}

.title {
  font-weight: 700;
  font-size: 36px;
}

.description-big {
  color: black;
  /* text-shadow: -0.5px 0 white, 0 0.5px white, 0.5px 0 white, 0 -0.5px white; */
  font-size: 24px;
  font-weight: 400;
}

.description-little {
  color: black;
  /* text-shadow: -0.5px 0 white, 0 0.5px white, 0.5px 0 white, 0 -0.5px white; */
  font-size: 18px;
  font-weight: 400;
}

.display-pic {
  height: 40vh;
  width: 40vw;
}

.pic-container {
  text-align: center;
}
