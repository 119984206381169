.text-container{
    display: flex;
    margin-left: 22%;
    margin-right: 22%;
    margin-top: 7%;
    flex-direction:column;
    justify-content: space-between;
}

.hide-text{
    display: none;
}

.read-more{
    text-align:center;
    color: gray;
}

.read-more:hover{
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .text-container {
    display: flex;
    margin-top: 4%;
    margin-left: 4%;
    margin-right: 4%;
    flex-direction:column;
    justify-content: space-between;
  }
}

.intro-header{
    padding-top: 0%;
    margin-top: 3%;
    margin-bottom: 2%;
}

.entry{
    font-family: sans-serif;
    font-size: large;
}

.profile-pic{
    height: 30vh;
    width:30vh;
    border-radius: 50%;
}

.profile-pic-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color:rgb(211, 3, 3);
}

hr{
    color: darkslategray;
    background-color: darkslategrAY;
    height: 2.5px;
    width: 100%;
}

.projects-header{
    align-self: center;
    color:rgb(211, 3, 3);
    text-align: center;
}

.project-card-container{
    display: flex;
    flex-direction: column;
}

.project-card{
    align-self: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
}

.project-image{
    width: 90%;
}

.project-text{
    justify-self: center;
    text-align: center;
    color: darkslategray;
}

.button-container{
    width:100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    justify-content: space-around;
}

.project-button{
    width: 100%;
    height:100%;
}

.project-link{
    width: 28%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    margin-top: 2.5%;
}

.project-link-large{
    width: 45%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    margin-top: 2.5%;
}


.description-big{
    color: darkslategrey !important;
    font-size: large;
    margin-top: 5%
}

.description-little{
    color: darkslategray !important;
}

.back-button{
    width: 30%;
    align-self: center;
    margin-top:3%;
}

.bottom{
    margin-bottom: 10%;
}

.nav-table{
    display: flex;
    margin-right: 30%;
    margin-top: 5%;
    height: 15vh;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.nav-table-cell{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: large;
    font-weight: 600;
    color: #2147de;
}

.nav-table-cell:hover{
    font-size: larger;
    font-weight: 800;
}

.right-arrow{
    height: 5vh;
    width: 5vw;
}

.contact-container{
    display: flex;
    flex-direction: column;
}

.contact-form{
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
}

.contact-name{
    display:flex;
    justify-content: space-between;
}

.contact-table{
    width: 100%;
}

.contact-cell-small{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.right-hand-cell{
    align-self: flex-end;
    justify-self: flex-end;
    align-items: end;
}

.last-name-label{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 95%;
}

.contact-input-small{
    width: 95%;
}

.contact-cell-medium{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
}

.contact-input-medium{
    width: 80%;
}

.contact-cell-large{
    width: 100%;
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 2vh;
}

.contact-input-large{
    width: 100%;
    height: 20vh;
}

.submit-button{
    width: 100%;
}

.result-message{
    text-align: center;
    color: green;
    margin-top: 5%;
}